import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import '../styles/main.css'

const NotFound: React.FC = () => {
  const [sellerName, setSellerName] = useState<string>(localStorage.getItem("sellerName") || "Unknown");
  const [deposits, setDeposits] = useState<number>(0);
  const [appleJuice, setAppleJuice] = useState<number>(0);
  const [blackcurrantJuice, setBlackcurrantJuice] = useState<number>(0);
  const [spezi, setSpezi] = useState<number>(0);
  const [water, setWater] = useState<number>(0);
  const [logs, setLogs] = useState<string[]>([]);

  useEffect(() => {
    async function fetchData() {
      const depositsValue = await getDeposits();
      setDeposits(depositsValue);

      const appleJuiceValue = await getAppleJuice();
      setAppleJuice(appleJuiceValue);

      const blackcurrantJuiceValue = await getBlackcurrantJuice();
      setBlackcurrantJuice(blackcurrantJuiceValue);

      const speziValue = await getSpezi();
      setSpezi(speziValue);

      const waterValue = await getWater();
      setWater(waterValue);

      const logsValue = await getLogs();
      setLogs(logsValue);
    }

    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function buyItem(itemName: string) {
    const response = await fetch("https://africawater.theserver.host/api/buyItem", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ itemName, sellerName }),
    });

    const json: any = await response.json();

    if(json.status === "success") {
      toast.success("Erfolgreich gekauft", { autoClose: 15000 });
      console.log("Erfolgreich gekauft");
      getDeposits().then((deposits) => setDeposits(deposits));
      getAppleJuice().then((appleJuice) => setAppleJuice(appleJuice));
      getBlackcurrantJuice().then((blackcurrantJuice) => setBlackcurrantJuice(blackcurrantJuice));
      getSpezi().then((spezi) => setSpezi(spezi));
      getWater().then((water) => setWater(water));
      getLogs().then((logs) => setLogs(logs));
    } else {
      toast.error("Kauf fehlgeschlagen");
      console.log("Kauf fehlgeschlagen");
    }
  }

  async function doRefund(itemName: string): Promise<void> {
    const response = await fetch("https://africawater.theserver.host/api/refundItem", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ itemName, sellerName }),
    });

    const json: any = await response.json();

    if(json.status === "success") {
      toast.success("Erfolgreich refunded", { autoClose: 15000 });
      console.log("Erfolgreich refunded");
      getDeposits().then((deposits) => setDeposits(deposits));
      getAppleJuice().then((appleJuice) => setAppleJuice(appleJuice));
      getBlackcurrantJuice().then((blackcurrantJuice) => setBlackcurrantJuice(blackcurrantJuice));
      getSpezi().then((spezi) => setSpezi(spezi));
      getWater().then((water) => setWater(water));
      getLogs().then((logs) => setLogs(logs));
    } else {
      toast.error("Refund fehlgeschlagen");
      console.log("Refund fehlgeschlagen");
    }
  }

  async function refundItem(itemName: string): Promise<void> {
    confirmAlert({
      title: 'Bist Du Sicher?',
      message: 'Willst du diese Aktion wirklich durchführen? Sie ist nicht rückgängig zu machen.',
      buttons: [
        {
          label: 'Ja',
          onClick: () => doRefund(itemName)
        },
        {
          label: 'Nein',
          onClick: () => null
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      willUnmount: () => {},
      onClickOutside: () => {},
      onKeypressEscape: () => {}
    });
  }

  async function deposit(): Promise<void> {
    const response = await fetch("https://africawater.theserver.host/api/deposit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ sellerName }),
    });

    const json: any = await response.json();

    if(json.status === "success") {
      toast.success("Pfand abgegeben", { autoClose: 15000 });
      console.log("Pfand abgegeben");
      getDeposits().then((deposits) => setDeposits(deposits));
      getAppleJuice().then((appleJuice) => setAppleJuice(appleJuice));
      getBlackcurrantJuice().then((blackcurrantJuice) => setBlackcurrantJuice(blackcurrantJuice));
      getSpezi().then((spezi) => setSpezi(spezi));
      getWater().then((water) => setWater(water));
      getLogs().then((logs) => setLogs(logs));
    } else {
      toast.error("Pfand fehlgeschlagen");
      console.log("Pfand fehlgeschlagen");
    }
  }

  function formatDate(inputString: string) {
    const date = new Date(inputString);
    
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-indexed in UTC as well
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');    
    
    return `${day}.${month}. ${hours}:${minutes}`;
  }

  async function getLogs(): Promise<string[]> {
    const response = await fetch("https://africawater.theserver.host/api/getLogs", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const json: any = await response.json();
    console.log(json.map((x: any) => x.action_description + " | " + formatDate(x.timestamp)))

    return json.map((x: any) => x.action_description + " | " + formatDate(x.timestamp));
  }

  async function getDeposits(): Promise<number> {
    const response = await fetch("https://africawater.theserver.host/api/getDeposits", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const json: any = await response.json();

    return json.totalDeposits || 0;
  }

  async function getAppleJuice(): Promise<number> {
    const response = await fetch("https://africawater.theserver.host/api/getAppleJuice", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const json: any = await response.json();

    return json.quantity || 0;
  }

  async function getBlackcurrantJuice(): Promise<number> {
    const response = await fetch("https://africawater.theserver.host/api/getBlackcurrantJuice", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const json: any = await response.json();

    return json.quantity || 0;
  }

  async function getSpezi(): Promise<number> {
    const response = await fetch("https://africawater.theserver.host/api/getSpezi", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const json: any = await response.json();

    return json.quantity || 0;
  }

  async function getWater(): Promise<number> {
    const response = await fetch("https://africawater.theserver.host/api/getWater", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const json: any = await response.json();

    return json.quantity || 0;
  }

  function changeSeller(event: React.ChangeEvent<HTMLInputElement>): void {
    console.log("Seller Name Changed: " + event.target.value);
    localStorage.setItem("sellerName", event.target.value);
    setSellerName(event.target.value);
  }

  return (
    <div className="page">
        <Helmet>
            <title>Warenwirtschaftssystem</title>
        </Helmet>
        <div className="container">
          <div className="seller-input">
              <label htmlFor="sellerName">Aktueller Verkäufer:</label>
              <input type="text" id="sellerName" name="sellerName" value={sellerName} onChange={changeSeller} />
          </div>
          <div className="product">
              <h4>Apfelschorle</h4>
              <p>Auf Lager: <span id={appleJuice === 0 ? "red" : appleJuice < 5 ? "orange" : "green"}>{appleJuice}</span></p>
              <button id="buy" onClick={() => buyItem("APS")}>Kauf</button>
              <button id="refund" onClick={() => refundItem("APS")}>Refund</button>
          </div>
          <div className="product">
              <h4>Johannisbeerschorle</h4>
              <p>Auf Lager: <span id={blackcurrantJuice === 0 ? "red" : blackcurrantJuice < 5 ? "orange" : "green"}>{blackcurrantJuice}</span></p>
              <button id="buy" onClick={() => buyItem("JHS")}>Kauf</button>
              <button id="refund" onClick={() => refundItem("JHS")}>Refund</button>
          </div>
          <div className="product">
            <h4>Spezi</h4>
            <p>Auf Lager: <span id={spezi === 0 ? "red" : spezi < 5 ? "orange" : "green"}>{spezi}</span></p>
            <button id="buy" onClick={() => buyItem("SPZ")}>Kauf</button>
            <button id="refund" onClick={() => refundItem("SPZ")}>Refund</button>
          </div>
          <div className="product">
            <h4>Wasser</h4>
            <p>Auf Lager: <span id={water === 0 ? "red" : water < 5 ? "orange" : "green"}>{water}</span></p>
            <button id="buy" onClick={() => buyItem("H2O")}>Kauf</button>
            <button id="refund" onClick={() => refundItem("H2O")}>Refund</button>
          </div>
          <div className="product">
            <h4>Kaffee</h4>
            <p>Auf Lager: Unendlich</p>
            <button id="buy" onClick={() => buyItem("KFE")}>Kauf</button>
            <button id="refund" onClick={() => refundItem("KFE")}>Refund</button>
          </div>
          
          <div className="deposit">
              <h4>Pfand Zähler</h4>
              <button id="deposit" onClick={() => deposit()}>-1</button>
              <p>Unauszegahlter Pfand: <span id={deposits === 0 ? "orange" : deposits < 0 ? "red" : "green"}>{deposits} €</span></p>
          </div>
    </div>

    <div className="logs">
        <h4>History | Logs</h4>
        {logs.map((log, index) => (
            <p key={index}>{log}</p>
        ))}
    </div>
    </div>
  );
}

export default NotFound;
